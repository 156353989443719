import React from 'react'
import PropTypes from 'prop-types'
import Content from './Content'
import {Link} from 'gatsby'
import Image from 'gatsby-image'
import remark from 'remark'
import {HTMLContent} from './Content'
import remarkHtml from 'remark-html'
// import { HorizontalBar } from 'react-chartjs-2'

const BillPageTemplate = ({ title, notice, content, contentComponent, asideImages, rateSchedule }) => {
  const PageContent = contentComponent || Content

  function Notice({notice}) {
    if(notice.title !== null) {
      return (
        <article className="message">
          <div className="message-header">
            {notice.title}
          </div>
          <div className="message-body">
            <HTMLContent content={remark().use(remarkHtml).processSync(notice.message).toString()}/>
          </div>
        </article>
      )
    }
    else {
      return (
        < ></ >
      )
    }
  }

  function AsideImages({asideImages}) {
    console.log(asideImages)
    if( !Array.isArray(asideImages) || !asideImages.length) {
      return (
        < ></ >
      )
    }

    return (
      <div className="column is-3 vertical-justify">
          <div style={{marginTop: '2rem', marginBottom: '1rem'}}>
          <h4 className='title is-size-4'>Operator</h4>
          <p><strong>Water District Management Company</strong><br />
          Attn: Dennis Wright<br />
          17707 Old Louetta<br />
          Houston, TX 77070<br />
          (281) 376-8802<br />
          <a href="https://www.wdmtexas.com/">Website</a></p>
          </div>
        {asideImages.map(image => (
          < >
            <Image className="asideImage" fixed={image.image.childImageSharp.fixed} alt={image.image.alt} />
          </ >
        ))}
      </div>
    )
  }

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-7 is-offset-1">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2>
          </div>
        </div>
        <div className="columns">
          <div className="column is-7 is-offset-1">
            <div className="section">
              <PageContent className="content" content={content} />
            </div>
            <div className="section">
              <h2 className="title is-size-3">Water Rate Info</h2>
              {/* <p>Use the chart below to estimate your water bill. <br /><small><strong>Note:</strong> Legend items can be toggled by clicking on them.</small></p> */}
              {/* <HorizontalBar data={{
                labels: ["5k gal", "10k gal", "15k gal", "20k gal", "25k gal", "30k gal", "35k gal"],
                datasets: [
                  {
                    label: "Water Usage",
                    backgroundColor: "rgba(36,67,108,0.5)",
                    strokeColor: "rgba(151,187,205,0.8)",
                    highlightFill: "rgba(151,187,205,0.75)",
                    highlightStroke: "rgba(151,187,205,1)",
                    data: [9, 13, 18, 23, 28, 33, 38]
                  },
                  {
                    label: "Sewer",
                    backgroundColor: "rgba(38,132,135,0.5)",
                    strokeColor: "rgba(151,187,205,0.8)",
                    highlightFill: "rgba(151,187,205,0.75)",
                    highlightStroke: "rgba(151,187,205,1)",
                    data: [12, 17, 22, 27, 32, 37, 42]
                  },
                  {
                    label: "RWA Fee",
                    backgroundColor: "rgba(144,173,86,0.5)",
                    strokeColor: "rgba(220,220,220,0.8)",
                    highlightFill: "rgba(220,220,220,0.75)",
                    highlightStroke: "rgba(220,220,220,1)",
                    data: [16.25,32.5, 48.75, 65, 81.25, 97.5, 113.75]
                  }
                ]
              }}
              options={{
                scales: {
                  xAxes: [{
                    stacked: true
                  }],
                  yAxes: [{
                    stacked: true
                  }]
                },
                tooltips: {
                  mode: 'label',
                  displayColors: false,
                  callbacks: {
                      label: function(tooltipItem, data) {
                          var corporation = data.datasets[tooltipItem.datasetIndex].label;
                          var valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                          var total = 0;
                          for (var i = 0; i < data.datasets.length; i++)
                              total += data.datasets[i].data[tooltipItem.index];
                          if (tooltipItem.datasetIndex != data.datasets.length - 1) {
                              return corporation + " : $" + valor.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
                          } else {
                              return [corporation + " : $" + valor.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,'), "Total : $" + total.toFixed(2)];
                          }
                      }
                  }
                },
                scaleBeginAtZero: true
              }} width="600" height="400" /> */}
              {/* <h3 className="title is-size-4" style={{ marginLeft: '-1.5rem', marginTop: '2rem'}}>Rate Schedule</h3> */}
              <p>Below shows the water rate schedule. <br/>A copy of the current rate order is available from the <Link to="/documents"><i>Public Documents</i> page</Link>.</p>
              <table className="table">
              <tbody>
              {rateSchedule.map( entry => (
                <tr>
                  <td>$&nbsp;{entry.rate.toFixed(2)}</td>
                  {/* <td>$&nbsp;---</td> */}
                  <td>{entry.description}</td>
                </tr>
              ))}
              </tbody>
              </table>
              <Notice notice={notice} />
            </div>
          </div>
          <AsideImages asideImages={asideImages} />
        </div>
      </div>
    </section>
  )
}

BillPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default BillPageTemplate
