import React from "react"
// import { Link, StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import logo from "../img/logo.svg"
import NavbarMenu from "./NavbarMenu"

class Navbar extends React.Component {
  render() {
    function toggleMenu() {
      document.querySelector(".navbar-burger").classList.toggle("is-active")
      document.querySelector(".navbar-menu").classList.toggle("is-active")
      console.log("menu pressed")
    }
    return (
      < >
      <nav className="navbar">
        <div className="container" style={{ alignItems: "center" }}>
          <div className="navbar-brand">
            <Link to="/" className="">
              <figure>
                <img
                  src={logo}
                  alt="Encanto Real UD"
                  className="logo"
                />
              </figure>
            </Link>
            <a
              className="navbar-burger"
              onClick={toggleMenu}
              role="button"
              aria-label="menu"
              aria-expanded="false"
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </a>
          </div>
          <div className="navbar-menu">
            <div className="navbar-end">
              <NavbarMenu />
            </div>
          </div>
        </div>
      </nav>
      <div className="nav-spacer"></div>
      </ >
    )
  }
}

export default Navbar
