import React from "react"
import Helmet from "react-helmet"

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import "typeface-ubuntu"
import "./all.sass"

const TemplateWrapper = ({ children }) => (
  <>
    <Helmet title="Encanto Real Utility Distict" />
    <Navbar />
    {children}
    <Footer />
  </>
)

export default TemplateWrapper
